import { effect, EffectRef, inject, Injectable, OnDestroy, signal, untracked, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';

import { RxStompConfig, RxStompState } from '@stomp/rx-stomp';

import { stompConfig } from '@core/configurations/stomp.config';
import { TypeEnum } from '@core/enums/type.enum';
import { ClientManvData } from '@core/models/clientManvData';
import { ClientManvEvent } from '@core/models/clientManvEvent';
import { ClientManvPatient } from '@core/models/clientManvPatient';
import { ClientPatientMonitorMessage } from '@core/models/clientPatientMonitorMessage';
import { ClientStatus } from '@core/models/clientStatus';
import { ClientUserSetting } from '@core/models/clientUserSetting';
import { ClientWebsocketMessage } from '@core/models/clientWebsocketMessage';
import { EventData } from '@core/models/eventData';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenService } from '@core/services/auth/token.service';
import { DashboardTokenService } from '@core/services/dashboard-token.service';
import { EventBusService } from '@core/services/event-bus.service';
import { OrganizationRoles, RoleService } from '@core/services/role.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { UserService } from '@core/services/user.service';

import { DepartmentBedService } from '@shared/services/department-bed.service';
import { DeviceService } from '@shared/services/device.service';
import { DiagnosisResourceMappingService } from '@shared/services/diagnosis-resource-mapping.service';
import { DivonoService } from '@shared/services/divono.service';
import { EmergencyCareService } from '@shared/services/emergency-care.service';
import { HospitalService } from '@shared/services/hospital.service';
import { IntensiveCareUnitBedService } from '@shared/services/intensive-care-unit-bed.service';
import { PatientMonitorService } from '@shared/services/patient-monitor.service';

import { ManvEventService } from '@manv/services/manv-event.service';
import { ManvHospitalDataService } from '@manv/services/manv-hospital-data.service';
import { ManvPatientService } from '@manv/services/manv-patient.service';

import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  #authService: AuthService = inject(AuthService);
  #dashboardTokenService: DashboardTokenService = inject(DashboardTokenService);
  #hospitalService: HospitalService = inject(HospitalService);
  #emergencyCareService: EmergencyCareService = inject(EmergencyCareService);
  #departmentBedService: DepartmentBedService = inject(DepartmentBedService);
  #intensiveCareUnitBedService: IntensiveCareUnitBedService = inject(IntensiveCareUnitBedService);
  #deviceService: DeviceService = inject(DeviceService);
  #diagnosisResourceMappingService: DiagnosisResourceMappingService = inject(DiagnosisResourceMappingService);
  private divonoService: DivonoService = inject(DivonoService);
  private eventBusService: EventBusService = inject(EventBusService);
  private patientMonitorService: PatientMonitorService = inject(PatientMonitorService);
  private tokenService: TokenService = inject(TokenService);
  private userService: UserService = inject(UserService);
  private roleService: RoleService = inject(RoleService);
  private userSettingService: UserSettingService = inject(UserSettingService);
  private manvEventService: ManvEventService = inject(ManvEventService);
  private manvPatientService: ManvPatientService = inject(ManvPatientService);
  private manvHospitalDataService: ManvHospitalDataService = inject(ManvHospitalDataService);
  #router = inject(Router);

  #webSocketWorker: Worker;
  public connectionState: WritableSignal<RxStompState> = signal(RxStompState.CLOSED);

  #destinationToMessageHandlerMap: WritableSignal<Map<string, (message: string) => void>> = signal(
    new Map<string, () => void>()
  );

  #connectionEffect: EffectRef = effect(() => {
    if (this.#authService.authenticated()) {
      this.configureBrokerUrlWithToken(this.tokenService.token());
      this.connect();
    } else if (this.#authService.dashboardTokenSet()) {
      this.configureBrokerUrlWithToken(this.#dashboardTokenService.dashboardToken());
      this.connect();
    } else {
      this.configureBrokerUrlWithToken();
      if (this.connectionState() !== RxStompState.CLOSED) {
        untracked(() => this.disconnect());
      }
    }
  });

  constructor() {
    this.#webSocketWorker = new Worker(new URL('../worker/websocket.worker', import.meta.url));
    this.setupWebSocketWorkerListener();
  }

  private setupWebSocketWorkerListener(): void {
    this.#webSocketWorker.onmessage = ({ data }) => {
      const { type, payload } = data;
      switch (type) {
        case 'message':
          this.handleMessage(payload);
          break;
        case 'connection':
          this.connectionState.set(payload);
          break;
        case 'info':
        case 'error':
          break;
      }
    };
  }

  private handleMessage({ destination, body }: { destination: string; body: string }): void {
    const messageHandler = this.#destinationToMessageHandlerMap().get(destination);
    if (messageHandler) {
      try {
        messageHandler(body);
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    } else {
      console.error('message without parser');
    }
  }

  ngOnDestroy(): void {
    this.#webSocketWorker.postMessage({ action: 'terminate' });
  }

  public configureBrokerUrlWithToken(token?: string): void {
    const updatedConfig: RxStompConfig = stompConfig;
    if (token) {
      updatedConfig.brokerURL = environment.websocket_url + '?token=' + token;
    } else {
      updatedConfig.brokerURL = environment.websocket_url;
    }
    this.#webSocketWorker.postMessage({ action: 'configure', payload: updatedConfig });
  }

  public connect(): void {
    this.#webSocketWorker.postMessage({ action: 'connect' });
  }

  public disconnect(): void {
    this.unsubscribe('*');
    this.#webSocketWorker.postMessage({ action: 'disconnect' });
  }

  #hospitalSubscriptionsEffect: EffectRef = effect(() => {
    if (this.#authService.authenticatedOrDashboardTokenSet()) {
      const hospitalIds: Set<string> = this.roleService.selectedHospitalIds();

      untracked(() => {
        const map = this.#destinationToMessageHandlerMap();
        const topicPrefix = '/topic/hospital.';
        const hospitalsToSubscribe = Array.from(hospitalIds).filter((id: string) => !map.has(topicPrefix + id));
        const hospitalIdsWithPrefixToUnsubscribe = Array.from(map.keys()).filter(
          (id: string) => id.startsWith(topicPrefix) && !hospitalIds.has(id.replace(topicPrefix, ''))
        );
        for (const id of hospitalsToSubscribe) {
          this.subscribeToHospitalId(id, (message: string) => {
            const newStatus = JSON.parse(message) as ClientStatus;
            switch (newStatus.type) {
              case TypeEnum.AB:
                this.#departmentBedService.updateDepartmentBedFromStatus(newStatus);
                break;
              case TypeEnum.AV:
                this.#emergencyCareService.updateEmergencyCareFromStatus(newStatus);
                break;
              case TypeEnum.IN:
                this.#intensiveCareUnitBedService.updateIntensiveCareUnitBedFromStatus(newStatus);
                break;
              case TypeEnum.KL:
                this.#hospitalService.updateHospitalFromStatus(newStatus);
                break;
              case TypeEnum.TN:
                this.#deviceService.updateDeviceFromStatus(newStatus);
                break;
            }
          });
        }

        for (const id of hospitalIdsWithPrefixToUnsubscribe) {
          this.unsubscribe(id);
        }
      });
    }
  });

  #userSubscriptionEffect: EffectRef = effect(() => {
    const token = this.tokenService.decodedToken();
    const dashboardToken = this.#dashboardTokenService.dashboardToken();

    untracked(() => {
      if (dashboardToken || token) {
        const destination = '/topic/user.' + (dashboardToken ?? token['sub']);
        if (destination && !this.#destinationToMessageHandlerMap().has(destination)) {
          this.subscribeToTopic(destination, (message: string) => {
            const websocketMessage = JSON.parse(message) as ClientWebsocketMessage;
            if (websocketMessage.message) {
              this.eventBusService.emit(new EventData(websocketMessage.message, null));
            }
          });
        }
      }
    });
  });

  #userSettingSubscriptionEffect: EffectRef = effect(() => {
    const token = this.tokenService.decodedToken();
    const dashboardToken = this.#dashboardTokenService.dashboardToken();

    if (dashboardToken || token) {
      const destination = '/topic/userSetting.' + (dashboardToken ?? token['sub']);
      untracked(() => {
        if (destination && !this.#destinationToMessageHandlerMap().has(destination)) {
          this.subscribeToTopic(destination, (message: string) => {
            const clientUserSetting = JSON.parse(message) as ClientUserSetting;
            this.userSettingService.currentUserSetting.set(clientUserSetting);
          });
        }
      });
    }
  });

  #diagnosisResourceMappingEffect: EffectRef = effect(() => {
    if (this.#authService.authenticatedOrDashboardTokenSet()) {
      const destination = '/topic/diagnosisResourceMapping';
      untracked(() => {
        if (destination && !this.#destinationToMessageHandlerMap().has(destination)) {
          this.subscribeToTopic(destination, () => this.#diagnosisResourceMappingService.reload());
        }
      });
    }
  });

  #divonoSubscriptionEffect: EffectRef = effect(() => {
    if (!this.#authService.authenticatedOrDashboardTokenSet()) return;

    const organizationRole = this.divonoService.divonoDashboardConfig()?.type || this.roleService.organizationRole();
    if (organizationRole === OrganizationRoles.GUEST) return;

    const topicPrefix = '/topic/divono.';
    const subscribeToTopic = (id: string) => {
      if (id && !this.#destinationToMessageHandlerMap().has(topicPrefix + id)) {
        this.subscribeToDivonoTopic(id, () => this.divonoService.updateDivonoMessages());
      }
    };

    const divonoConfig = this.divonoService.divonoDashboardConfig();
    const currentUser = this.userService.currentUser();

    untracked(() => {
      switch (organizationRole) {
        case OrganizationRoles.CONTROL_CENTER: {
          const controlTopic =
            divonoConfig?.rescueServiceAreaAbbreviation || currentUser?.rescueServiceAreaAbbreviation;
          subscribeToTopic(controlTopic);
          break;
        }
        case OrganizationRoles.HOSPITAL: {
          const hospitalIds = divonoConfig?.hospitalIds || this.roleService.fullWritableHospitalIds();
          hospitalIds.forEach(subscribeToTopic);
          break;
        }
        case OrganizationRoles.ADMIN: {
          subscribeToTopic('*');
        }
      }
    });
  });

  #patientMonitorSubscriptionEffect: EffectRef = effect(() => {
    if (this.#authService.authenticatedOrDashboardTokenSet()) {
      const hospitalIds = this.roleService.selectedHospitalIds();

      untracked(() => {
        const map = this.#destinationToMessageHandlerMap();
        const topicPrefix = '/topic/patientmonitor.';
        const hospitalsToSubscribe = Array.from(hospitalIds).filter((id: string) => !map.has(topicPrefix + id));
        const hospitalIdsWithPrefixToUnsubscribe = Array.from(map.keys()).filter(
          (id: string) => id.startsWith(topicPrefix) && !hospitalIds.has(id.replace(topicPrefix, ''))
        );

        for (const id of hospitalsToSubscribe) {
          this.subscribeToPatientMonitor(id, (message: string) => {
            const newStatus = JSON.parse(message) as ClientPatientMonitorMessage;
            this.patientMonitorService.updatePatientMonitorMessages(newStatus);
          });
        }

        for (const id of hospitalIdsWithPrefixToUnsubscribe) {
          this.unsubscribe(id);
        }
      });
    }
  });

  #manvSubscriptionEffect: EffectRef = effect(() => {
    if (this.#authService.authenticated()) {
      const organizationRole = this.roleService.organizationRole();

      if (organizationRole !== OrganizationRoles.GUEST) {
        untracked(() => {
          const topicPrefix = '/topic/manv.';

          if (!this.#destinationToMessageHandlerMap().has(topicPrefix + 'event')) {
            this.subscribeToManvTopic('event', (message: string) => {
              const newEvent = JSON.parse(message) as ClientManvEvent;
              this.manvEventService.updateManvEventMessages(newEvent);
            });
          }

          if (!this.#destinationToMessageHandlerMap().has(topicPrefix + 'patient')) {
            this.subscribeToManvTopic('patient', (message: string) => {
              const newPatient = JSON.parse(message) as ClientManvPatient;
              this.manvPatientService.updateManvPatientMessages(newPatient);
            });
          }

          if (!this.#destinationToMessageHandlerMap().has(topicPrefix + 'capacity')) {
            this.subscribeToManvTopic('capacity', (message: string) => {
              const newCapacity = JSON.parse(message) as Pick<
                ClientManvData,
                'id' | 'created' | 'level' | 'current' | 'followUpRequested' | 'followUpConfirmed' | 'dutyOverrides'
              >;
              this.manvHospitalDataService.updateManvHospitalDataMessages(newCapacity);
            });
          }
        });
      }
    }
  });

  #dashboardTokenSubscriptionEffect: EffectRef = effect(() => {
    if (this.#authService.dashboardTokenSet()) {
      const topic = '/topic/dashboardToken.' + this.#dashboardTokenService.dashboardToken();
      untracked(() => {
        if (!this.#destinationToMessageHandlerMap().has(topic)) {
          this.subscribeToTopic(topic, (message: string) => {
            const websocketMessage = JSON.parse(message) as ClientWebsocketMessage;
            if (websocketMessage.message === 'invalidate') {
              this.disconnect();
              this.#router.navigate(['/']);
            }
          });
        }
      });
    }
  });

  public subscribeToHospitalId(hospitalId: string, messageHandler: (message: string) => void): void {
    this.subscribeToTopic('/topic/hospital.' + hospitalId, messageHandler);
  }

  public subscribeToDivonoTopic(topic: string, messageHandler: (message: string) => void): void {
    this.subscribeToTopic('/topic/divono.' + topic, messageHandler);
  }

  public subscribeToPatientMonitor(hospitalId: string, messageHandler: (message: string) => void): void {
    this.subscribeToTopic('/topic/patientmonitor.' + hospitalId, messageHandler);
  }

  public subscribeToManvTopic(topic: string, messageHandler: (message: string) => void): void {
    this.subscribeToTopic('/topic/manv.' + topic, messageHandler);
  }

  public subscribeToTopic(topic: string, messageHandler: (message: string) => void): void {
    this.#webSocketWorker.postMessage({
      action: 'subscribe',
      payload: {
        destination: topic
      }
    });
    if (messageHandler) {
      this.#destinationToMessageHandlerMap.update(map => {
        const updatedMap = new Map(map);
        updatedMap.set(topic, messageHandler);
        return updatedMap;
      });
    }
  }

  private unsubscribe(topic: string): void {
    this.#webSocketWorker.postMessage({ action: 'unsubscribe', payload: { destination: topic } });
    if (topic === '*') {
      this.#destinationToMessageHandlerMap.set(new Map<string, (message: string) => void>());
      return;
    }
    if (this.#destinationToMessageHandlerMap().has(topic)) {
      this.#destinationToMessageHandlerMap.update(map => {
        const updatedMap = new Map(map);
        updatedMap.delete(topic);
        return updatedMap;
      });
    }
  }

  public sendMessage(destination: string, body: string): void {
    this.#webSocketWorker.postMessage({
      action: 'sendMessage',
      payload: {
        destination,
        body
      }
    });
  }
}
