import { RxStompConfig } from '@stomp/rx-stomp';

import { environment } from '@environments/environment';

export const stompConfig: RxStompConfig = {
  brokerURL: environment.websocket_url,
  heartbeatIncoming: 0,
  heartbeatOutgoing: 20000,
  connectionTimeout: 30000,
  reconnectDelay: 5000
};
